<template>
  <div
    class="box"
    style="background: linear-gradient(180deg, #00b5a0 0%, #0c9793 100%)"
  >
    <div class="top-box">
      <div>
        <p>Hi，{{ myInfo.name }}</p>
        <div class="riqi">
          <img
            style="width: 18px; height: 18px"
            src="https://middle-ground.shulanhealth.com/rili-icon%402x.png"
            alt=""
          />
          <span v-if="weatherInfo!=null"
            >今天是 {{ toDay }}，天气{{ weatherInfo.weather }}，气温{{
              weatherInfo.temperature
            }}°</span
          >
        </div>
      </div>
      <!-- <img class="head-sty" :src="'https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTL4Mluv3Vq2pwFZVfbeYUelic7xMjAheDCsQcvGVhG3lg6TpRrEZA7fQJzw2FduyWIGnBYFfbJq7nA/132'" alt=""> -->
    </div>
    <!-- <div class="dcx" v-if="myTx != null" @click="showPassengerInfo">你有1个行程</div> -->
    <div class="bar-box" style="margin-top: 20px">
      <van-tabs
        v-model="active"
        @change="onChange"
        color="rgb(12, 151, 147)"
        style="width: 100%; border-top-right-radius: 15px"
      >
        <van-tab title="全部订单" style="background: #fff">
          <div style="padding-top: 15px;;">
            <van-card
              v-for="(item, index) in orderList" :key="index"
              :num="item.goods.goodsNum"
              :price="item.goods.goodsPrice"
              :title="item.goods.goodsName"
              :thumb="formatPic(item.goods.goodsPic)"
            >
              <template #tags>
                <van-tag color="rgb(12, 151, 147)" plain type="danger" style="margin-top: 5px;">{{item.goods.goodsUnit}}</van-tag>
                <van-tag color="rgb(12, 151, 147)" plain type="danger" style="margin-left: 5px;margin-top: 5px;">{{item.goods.goodsCategory}}</van-tag>
              </template>
              <template #footer>
                <van-button size="small" v-if="item.order_status == 0" round>取消订单</van-button>
                <van-button size="small" v-if="item.order_status == 1" round @click="backOrders(item)">申请退款</van-button>
                <van-button size="small" round>查看详情</van-button>
                <van-button size="small" round v-if="item.order_status == 2">已退款</van-button>
                <van-button size="small" type="danger" v-if="item.order_status == 0" round @click="nowPay(item.id)">立即支付</van-button>
              </template>
            </van-card>
          </div>
        </van-tab>
        <van-tab title="待付款" style="background: #fff;" :badge="badge0">
          <div style="padding-top: 15px;;">
            <van-card
              v-for="(item, index) in orderList" :key="index"
              :num="item.goods.goodsNum"
              :price="item.goods.goodsPrice"
              :title="item.goods.goodsName"
              :thumb="formatPic(item.goods.goodsPic)"
            >
              <template #tags>
                <van-tag color="rgb(12, 151, 147)" plain type="danger" style="margin-top: 5px;">{{item.goods.goodsUnit}}</van-tag>
                <van-tag color="rgb(12, 151, 147)" plain type="danger" style="margin-left: 5px;margin-top: 5px;">{{item.goods.goodsCategory}}</van-tag>
              </template>
              <template #footer>
                <van-button size="small" v-if="item.order_status == 0" round>取消订单</van-button>
                <van-button size="small" v-if="item.order_status == 1" round @click="backOrders(item)">申请退款</van-button>
                <van-button size="small" round>查看详情</van-button>
                <van-button size="small" round v-if="item.order_status == 2">已退款</van-button>
                <van-button size="small" type="danger" v-if="item.order_status == 0" round @click="nowPay(item.id)">立即支付</van-button>
              </template>
            </van-card>
          </div>
        </van-tab>
        <van-tab title="已付款" style="background: #fff;" :badge="badge1">
          <div style="padding-top: 15px;;">
            <van-card
              v-for="(item, index) in orderList" :key="index"
              :num="item.goods.goodsNum"
              :price="item.goods.goodsPrice"
              :title="item.goods.goodsName"
              :thumb="formatPic(item.goods.goodsPic)"
            >
              <template #tags>
                <van-tag color="rgb(12, 151, 147)" plain type="danger" style="margin-top: 5px;">{{item.goods.goodsUnit}}</van-tag>
                <van-tag color="rgb(12, 151, 147)" plain type="danger" style="margin-left: 5px;margin-top: 5px;">{{item.goods.goodsCategory}}</van-tag>
              </template>
              <template #footer>
                <van-button size="small" v-if="item.order_status == 0" round>取消订单</van-button>
                <van-button size="small" v-if="item.order_status == 1" round @click="backOrders(item)">申请退款</van-button>
                <van-button size="small" round>查看详情</van-button>
                <van-button size="small" round v-if="item.order_status == 2">已退款</van-button>
                <van-button size="small" type="danger" v-if="item.order_status == 0" round>立即支付</van-button>
              </template>
            </van-card>
          </div>
        </van-tab>
        <van-tab title="已退款" style="background: #fff;" :badge="badge2">
          <div style="padding-top: 15px;;">
            <van-card
              v-for="(item, index) in orderList" :key="index"
              :num="item.goods.goodsNum"
              :price="item.goods.goodsPrice"
              :title="item.goods.goodsName"
              :thumb="formatPic(item.goods.goodsPic)"
            >
              <template #tags>
                <van-tag color="rgb(12, 151, 147)" plain type="danger" style="margin-top: 5px;">{{item.goods.goodsUnit}}</van-tag>
                <van-tag color="rgb(12, 151, 147)" plain type="danger" style="margin-left: 5px;margin-top: 5px;">{{item.goods.goodsCategory}}</van-tag>
              </template>
              <template #footer>
                <van-button size="small" v-if="item.order_status == 0" round>取消订单</van-button>
                <van-button size="small" v-if="item.order_status == 1" round @click="backOrders(item)">申请退款</van-button>
                <van-button size="small" round>查看详情</van-button>
                <van-button size="small" round v-if="item.order_status == 2">已退款</van-button>
                <van-button size="small" type="danger" v-if="item.order_status == 0" round>立即支付</van-button>
              </template>
            </van-card>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <empty :show="orderList.length == 0"></empty>
    <loading :show="isShowLoading"></loading>
    <van-popup v-model="showTishi" position="top">
      <div style="width: 100%; text-align: right">
        <img
          style="width: 55%"
          src="./comm/tishi.png"
          alt=""
          @click="closeTiShi"
        />
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Tab, Tabs, Toast, Popup, Dialog, Card } from "vant";
import dayjs from "dayjs";
import AMap from "AMap"; // 引入高德地图
import Empty from "./comm/empty.vue";
import Loading from "./comm/loading.vue";
import { passenger } from "@/api";
import { goods } from "@/api";
const wx = window.wx;
export default {
  components: {
    Empty: Empty,
    Loading: Loading,
    VanPopup: Popup,
    VanTab: Tab,
    VanTabs: Tabs,
    VanCard: Card,
  },
  data() {
    return {
      activeId: "",
      active: 0,
      opacitys: 0,
      opacitys1: 0,
      showTishi: false,
      items: [
        { text: "食品" },
        { text: "百货" },
        { text: "生活用品" },
        { text: "电器" },
        { text: "建筑用品" },
        { text: "服饰" },
        { text: "其他" },
      ],
      isShowLoading: true,
      value: 0,
      switch1: false,
      switch2: false,
      option: [
        { text: "全部区域", value: 0 },
        { text: "西湖区", value: 1 },
        { text: "滨江区", value: 2 },
      ],
      sfcList: [],
      myInfoType: null,
      myInfo: null,
      toDay: "",
      weatherInfo: null,
      myTx: null,
      orderList: [],
      badge0: '',
      badge1: '',
      badge2: '',
    };
  },

  mounted() {
    document.title = "闲置物品信息共享平台";
    this.toDay = dayjs().format("YYYY年MM月DD日");
    this.myInfoType = JSON.parse(localStorage.getItem("user")).types;
    this.myInfo = JSON.parse(localStorage.getItem("user"));
    this.getwxSign();
    this.getMyOrder();
    this.getOrderBadge();
    //加载天气查询插件
    let _this = this;
    AMap.plugin("AMap.Weather", function () {
      //创建天气查询实例
      var weather = new AMap.Weather();

      //执行实时天气信息查询
      weather.getLive("杭州市", function (err, data) {
        console.log(err, data);
        _this.weatherInfo = data;
      });
    });
    // console.log(this.dayjs().startOf('date'))
  },
  methods: {
    backOrders(e) {
      Dialog.confirm({
        title: '提醒',
        message: '确定退款？退款将在10分钟内到账',
      })
        .then(() => {
          this.isShowLoading = true
          goods.backOrder({order_id: e.id}).then(res => {
            console.log(res)
            this.isShowLoading = true
            Toast.success('退款成功')
            this.getMyOrder();
            this.getOrderBadge();
          })
        })
        .catch(() => {
          // on cancel
        });
      
    },
    nowPay(e) {
      let that = this
      goods.payOrder({order_id: e}).then(res => {
        console.log(res)
        // this.wxPay(res.data)
        window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest', res.data.data,
            function(res){
                if(res.err_msg == "get_brand_wcpay_request:ok" ) {
                  Toast.success('支付成功')
                  that.getMyOrder();
                  that.getOrderBadge();
                      // 使用以上方式判断前端返回,微信团队郑重提示：
                      // res.err_msg将在用户支付成功后返回
                      // ok，但并不保证它绝对可靠。
                }
            }
        );
      })
    },
    formatPic(e) {
      let ls = e.split(",");
      return `https://public.yayale.top/${ls[0]}`;
    },
    closeTiShi() {
      this.showTishi = false;
    },
    fengxiang(e) {
      let _this = this;
      if (this.myInfo.types == "2" && this.myInfo.status == "2") {
        Dialog.alert({
          title: "认证提醒",
          message: "您的车主审核尚未通过，请耐心等待",
        }).then(() => {
          // on close
          // this.getUserinfoAscy()
        });
        return;
      }
      wx.showMenuItems({
        menuList: ["menuItem:share:appMessage"], // 要显示的菜单项，所有 menu 项见附录3
      });
      this.showTishi = true;
      // wx.showMenuItems({
      //   menuList: ["menuItem:share:appMessage"], // 要显示的菜单项，所有 menu 项见附录3
      // });
      setTimeout(() => {
        let urls = `http://shweb.qiyasoft.top/login?resouce=info&id=${e.id}`;
        wx.updateAppMessageShareData({
          title: `${e.islong == 1 ? _this.getformat(e.tripDate) : "每天"} ${
            e.tripTime
          }`, // 分享标题
          desc: `${e.types == "1" ? "乘客" : "车主"}发布了${e.startAddress}-${
            e.endAddress
          }的行程，点击查看`, // 分享描述
          // link: `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf1e471795a1fd4e4&redirect_uri=${urls}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          link: urls,
          imgUrl:
            "https://middle-ground.shulanhealth.com/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20221025083427.jpg", // 分享图标
          success: function (res) {
            this.showTishi = false;
            if (res.err_msg == "shareWechatMessage:ok") {
              Toast.success("分享成功");
            }
          },
        });
      }, 1000);
    },
    getformat(e) {
      return dayjs(e).format("MM月DD日");
    },
    getwxSign() {
      let params = {
        url: document.location.href.split("#")[0],
      };
      passenger.getWxSign(params).then((res) => {
        if (res.data.code == 200) {
          wx.config({
            //beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: "wxf1e471795a1fd4e4", // 必填，企业微信的corpID
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
            jsApiList: [
              "onMenuShareTimeline",
              "onMenuShareAppMessage",
              "updateAppMessageShareData", //1.4 分享到朋友
              "updateTimelineShareData", //1.4分享到朋友圈
              "onMenuShareQQ",
              "onMenuShareWeibo",
              "onMenuShareQZone",
              "hideMenuItems",
              "showMenuItems",
              "chooseWXPay",
            ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
          });
          wx.ready(function () {
            wx.hideMenuItems({
              menuList: [
                "menuItem:share:appMessage",
                "menuItem:share:timeline",
                "menuItem:share:qq",
                "menuItem:share:weiboApp",
                "menuItem:share:appMessage",
                "menuItem:copyUrl",
                "menuItem:openWithQQBrowser",
                "menuItem:openWithSafari",
                "menuItem:share:brand",
                "menuItem:share:QZone",
                "menuItem:share:facebook",
                "menuItem:favorite",
                "menuItem:favorite",
              ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有 menu 项见附录3
            });
            // alert("ready");
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
          });
          wx.error(function (res) {
            this.tsRemark = JSON.stringify(res);
            // alert(JSON.stringify(res), "error");
            location.reload();
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          });
        }
      });
    },
    getMyOrder() {
      this.orderList = [];
      this.isShowLoading = true;
      let params = {
        order_status: this.active,
      };
      goods.getMyOrder(params).then((res) => {
        if (res.data.code == 200) {
          this.isShowLoading = false;
          this.orderList = res.data.data;
        }
      });
    },
    getOrderBadge() {
      let params = {};
      goods.getOrderBadge(params).then((res) => {
        if (res.data.code == 200) {
          this.isShowLoading = false;
          for(let i in res.data.data) {
            switch (res.data.data[i].order_status) {
              case '0':
                this.badge0 = res.data.data[i].count;
                break;
              case '1':
                this.badge1 = res.data.data[i].count;
                break;
              case '2':
                this.badge2 = res.data.data[i].count;
                break;
            }
          }
        }
      });
    },
    onChange(e) {
      console.log(e)
      this.active = e;
      this.getMyOrder()
    },
    formatCarNo(e) {
      const nBegin = e.substring(0, 2);
      const nEnd = e.substring(e.length - 2, e.length);
      return nBegin + "***" + nEnd;
    },
    goTo() {
      this.$router.push({
        name: "goods-add",
        query: {
          isAdd: "ADD",
        },
      });
    },
    goMy() {
      this.$router.push({
        name: "my-goods-list",
      });
    },
    searchXc() {
      this.getPassengerTripList();
    },
    showTrip(e) {
      console.log(e);
      this.$router.push({
        name: "goods-detail",
        query: {
          id: e.id,
        },
      });
    },
    showPassengerInfo() {
      this.$router.push({
        name: "passenger-info",
      });
    },
    onConfirm() {
      this.$refs.item.toggle();
    },
    time2(data) {
      if (data) {
        if (data == this.dayjs().format("YYYY-MM-DD")) {
          return "今天";
        } else if (
          this.dayjs(data) > this.dayjs() &&
          this.dayjs(data) < this.dayjs().add(1, "day")
        ) {
          return "明天";
        } else {
          return this.dayjs(data).format("MM月DD日");
        }
      }
    },
    getPassengerTripList() {
      this.isShowLoading = true;
      let params = {
        page: 1,
        pageSize: 15,
        searchkey: this.items[this.active].text,
      };
      console.log(params, 123);
      goods.getGoodsList(params).then((res) => {
        this.sfcList = res.data.data;
        this.isShowLoading = false;
      });
    },
    getPassengerTripDistrictList() {
      this.isShowLoading = true;
      let params = {};
      goods.getGoodsDistrictList(params).then((res) => {
        for (let i in res.data.data) {
          for (let j in this.items) {
            if (res.data.data[i].goodsCategory == this.items[j].text) {
              this.items[j].badge = res.data.data[i].count;
            }
          }
        }
        this.isShowLoading = false;
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log("*************qq***Test1****Enter**************");
    console.log("to", to);
    console.log("from", from);
    console.log("next", next);
    console.log(location.pathname);
    /* eslint-disable */
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      if (to.path !== location.pathname) {
        location.assign(to.fullPath);
      } else {
        next();
      }
    } else {
      next();
    }
  },
};
</script>
<style scoped>
/deep/ .van-tabs__nav {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.goods-img {
  width: 100%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  height: 150px;
  object-fit: cover;
}
/deep/ .van-popup {
  background: none;
}
/deep/ .van-tree-select__nav {
  flex: 0.6;
}
/deep/ .van-info {
  background: rgb(12, 151, 147);
}
/deep/ .van-sidebar-item--select::before {
  background-color: #42c6ff;
}
.top-box {
  width: 90%;
  margin-left: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 21px;
  z-index: 2;
}
.top-box div p {
  font-size: 16px;
  color: #fafafa;
  line-height: 0;
}
.riqi {
  display: flex;
  align-items: center;
}
.riqi img {
  margin-right: 5px;
}
.riqi span {
  font-size: 13px;
  color: #fafafa;
}
.head-sty {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.dcx {
  position: absolute;
  right: 0;
  bottom: 150px;
  z-index: 999;
  background: rgb(12, 151, 147);
  color: #fff;
  padding: 5px 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.wdxc {
  position: absolute;
  z-index: 9999;
  color: #fff;
}
.list-item {
  width: 92%;
  margin-left: 4%;
  background: #fff;
  box-shadow: 0px 2px 10px -4px rgba(2, 66, 58, 0.2);
  border-radius: 8px;
  /* padding: 15px 15px 10px 15px; */
  margin-bottom: 15px;
  box-sizing: border-box;
}
.cklx {
  border: 1px solid #2d83e8;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #2d83e8;
}
.bar-box {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}
.show-more {
  text-align: center;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #2d83e8;
  margin-bottom: 10px;
  cursor: pointer;
}
.bar-item {
  flex: 1;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background: #fff;
  justify-content: center;
}
.bar-item img {
  width: 20px;
  margin-right: 5px;
}
</style>
